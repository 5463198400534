import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaWhatsapp, FaBars } from "react-icons/fa";
import "../../styles/DataDisplay.css";
import { fetchData } from "../../services/api";

const converTo24HourFormat = (time) => {
  const [timePart, modifier] = time.split(" ");
  let [hours, minutes] = timePart.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}`;
};

const compareTimes = (a, b) => {
  const timeA = converTo24HourFormat(a.time);
  const timeB = converTo24HourFormat(b.time);
  return timeA.localeCompare(timeB);
};

const DataDisplay = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [showMenu, setShowMenu] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const menuRef = useRef(null);

  const handleAddData = () => {
    navigate("/login");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const handleWhatsappClick = () => {
    const phoneNumber = "919266014772";
    const whatsappUrl = `https://wa.me/${phoneNumber}`;

    try {
      window.open(whatsappUrl, "_blank");
    } catch (error) {
      console.error("Failed to open WhatsApp chat:", error);
      alert("Failed to open WhatsApp chat. Please try again.");
    }
  };

  const getUTCDate = (date) => {
    const utcDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    return utcDate;
  };

  useEffect(() => {
    const fetchTodaysData = async () => {
      const today = getUTCDate(new Date());
      setStartDate(today);
      const formattedDate = today.toISOString().split("T")[0];
      try {
        let fetchedData = await fetchData(formattedDate);
        setData(fetchedData.sort(compareTimes));
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([]);
      }
    };

    fetchTodaysData();
  }, []);

  const handleDateChange = async (date) => {
    const utcDate = getUTCDate(date);
    setStartDate(utcDate);
    const formattedDate = utcDate.toISOString().split("T")[0];
    try {
      let fetchedData = await fetchData(formattedDate);

      setData(fetchedData.sort(compareTimes)); // Replace instead of append
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([]);
    }
  };

  useEffect(() => {}, [data]);

  return (
    <div className="data-display-page">
      <header className="header mb-1">
        <div className="header-container text-center">
          <h1 className="shining-text">Big Satta Bazar</h1>
        </div>
      </header>

      <p className="rate-text text-center mb-2">जोड़ी दर: 10/900</p>
      <h3
        className="rate-text-h text-center mb-2"
        style={{ textDecoration: "underline" }}
      >
        All India Khaiwal
      </h3>

      <div className="info-box p-3 text-center">
        <p className="info-text mb-2">
          To play the game, click on the WhatsApp link below.
        </p>
        <p className="info-text mb-4">
          गेम खेलने के लिए नीचे दिए गए व्हाट्सएप लिंक पर क्लिक करें
        </p>
        <div className="whatsapp-sticker" onClick={handleWhatsappClick}>
          <FaWhatsapp size={60} />
        </div>
      </div>

      <div className="card mx-auto p-4 shadow rounded">
        <div className="card-header mb-2">
          <div className="date-picker">
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              className="form-control date-picker-input"
              popperPlacement="bottom-start" // Ensures the calendar opens below and aligns with input
            />
          </div>
          <div className="dropdown-menu-button" ref={menuRef}>
            <FaBars size={30} onClick={() => setShowMenu(!showMenu)} />
            {showMenu && (
              <div className="menu-dropdown">
                <ul className="menu-list">
                  <li className="menu-item" onClick={handleAddData}>
                    Add Data
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="data-table mb-4">
          <table className="table table-striped table-bordered text-center">
            <thead className="table-dark">
              <tr>
                <th>Time</th>
                <th>Delhi</th>
                <th>Mumbai</th>
                <th>Kolkata</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.time}</td>
                    <td>{item.Delhi}</td>
                    <td>{item.Mumbai}</td>
                    <td>{item.Kolkata}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No Data Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DataDisplay;
