import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchData = async (date) => {
  const response = await api.get(`api/data/date/${date}`);
  return response.data;
};

export const userLogin = async (data) => {
  try {
    const response = await api.post(`api/auth/login`, data);
    const { token } = response.data;

    if (token) {
      localStorage.setItem("authToken", token);
    }
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

export const addData = async (data) => {
  try {
    const token = localStorage.getItem("authToken");

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const response = await api.post("api/data", data, { headers });

    return response.data;
  } catch (error) {
    console.error("Error adding data:", error);
    throw error;
  }
};

export const deleteData = async (entryId) => {
  try {
    const token = localStorage.getItem("authToken");

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const response = await api.delete(`api/data/${entryId}`, { headers });

    return response.data;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};

export const updateData = async (entryId, updates) => {
  try {
    const token = localStorage.getItem("authToken");

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const response = await api.put(`api/data/${entryId}`, updates, { headers });

    return response.data;
  } catch (error) {
    console.error("Error updating data:", error);
    throw error;
  }
};
