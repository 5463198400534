import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DataDisplay from "./components/data/dataDisplay";
import Login from "./components/auth/Login";
import AdminDashboard from "./components/admin/adminDashboard";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<DataDisplay />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<AdminDashboard />} />
      </Routes>
    </Router>
  );
};

export default App;
