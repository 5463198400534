import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import { fetchData } from "../../services/api";
import { addData, updateData, deleteData } from "../../services/api";
import "../../styles/Admin.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Dropdown } from "bootstrap";
import { FaBars } from "react-icons/fa";

const AdminPage = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  const [time, setTime] = useState("");
  const [delhi, setDelhi] = useState("");
  const [mumbai, setMumbai] = useState("");
  const [kolkata, setKolkata] = useState("");
  const [selectedId, setSelectedId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const dropdownElementList = [].slice.call(
      document.querySelectorAll(".dropdown-toggle")
    );
    dropdownElementList.map(
      (dropdownToggleEl) => new Dropdown(dropdownToggleEl)
    );
  }, []);

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure you want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, logout",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("authToken");
        navigate("/", { replace: true });
        window.location.reload();
      }
    });
  };

  const getUTCDate = (date) => {
    const utcDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    return utcDate;
  };

  useEffect(() => {
    const fetchTodaysData = async () => {
      const today = getUTCDate(new Date());
      setStartDate(today);
      const formattedDate = today.toISOString().split("T")[0];
      try {
        let fetchedData = await fetchData(formattedDate);

        fetchedData.sort((a, b) => a.time.localeCompare(b.time));

        setData(fetchedData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([]);
      }
    };

    fetchTodaysData();
  }, []);

  const handleDateChange = async (date) => {
    const utcDate = getUTCDate(date);
    setStartDate(utcDate);
    const formattedDate = utcDate.toISOString().split("T")[0];
    try {
      let fetchedData = await fetchData(formattedDate);

      fetchedData.sort((a, b) => a.time.localeCompare(b.time));

      setData([...fetchedData]);
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([]);
    }
  };

  const resetForm = () => {
    setTime("");
    setDelhi("");
    setMumbai("");
    setKolkata("");
  };

  const openModal = () => {
    resetForm();
    setModalIsOpen(true);
  };
  const closeModal = () => {
    resetForm();
    setModalIsOpen(false);
  };

  const openEditModal = (item) => {
    setSelectedId(item.id);
    setTime(item.time);
    setDelhi(item.Delhi);
    setMumbai(item.Mumbai);
    setKolkata(item.Kolkata);
    setEditModalIsOpen(true);
  };
  const closeEditModal = () => setEditModalIsOpen(false);

  const handleAddData = async () => {
    if (!time) {
      Swal.fire("Error", "Time is a required field", "error");
      return;
    }

    const data = {
      time,
      Delhi: delhi || "",
      Mumbai: mumbai || "",
      Kolkata: kolkata || "",
    };

    try {
      await addData(data);
      Swal.fire("Success", "Data added successfully", "success");
      closeModal();
      handleDateChange(startDate);
    } catch (error) {
      console.error("Error adding data:", error);
      Swal.fire("Error", "Failed to add data", "error");
    }
  };

  const handleEditData = async () => {
    if (!time) {
      Swal.fire("Error", "Time is a required field", "error");
      return;
    }

    setSelectedId(data.id);
    const updatedData = {
      time,
      Delhi: delhi || "",
      Mumbai: mumbai || "",
      Kolkata: kolkata || "",
    };

    try {
      await updateData(selectedId, updatedData);
      Swal.fire("Success", "Data updated successfully", "success");
      closeEditModal();
      handleDateChange(startDate);
    } catch (error) {
      console.error("Error updating data:", error);
      Swal.fire("Error", "Failed to update data", "error");
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteData(id);
          Swal.fire("Deleted!", "Your data has been deleted.", "success");
          handleDateChange(startDate);
        } catch (error) {
          console.error("Error deleting data:", error);
          Swal.fire("Error", "Failed to delete data", "error");
        }
      }
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div className="data-display-page">
      <header className="header mb-1">
        <div className="header-container text-center">
          <h1 className="shining-text">Big Satta Bazar</h1>
        </div>
      </header>

      <div className="card mx-auto p-4 shadow rounded">
        <div className="card-header d-flex justify-content-between align-items-center mb-2">
          <div className="date-picker">
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              className="form-control date-picker-input"
            />
          </div>

          {/* Buttons for larger screens */}
          <div className="header-buttons d-none d-md-flex">
            <button className="btn btn-danger" onClick={handleLogout}>
              Logout
            </button>
            <button className="btn btn-primary" onClick={openModal}>
              Add Data
            </button>
          </div>

          <div className="menu-button" ref={menuRef}>
            <FaBars size={30} onClick={() => setShowMenu(!showMenu)} />
            {showMenu && (
              <div className="menu-dropdown">
                <ul className="menu-list">
                  <li className="menu-item-add" onClick={openModal}>
                    Add Data
                  </li>
                  <li className="menu-logout" onClick={handleLogout}>
                    Logout{" "}
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="data-table mb-4">
          <table className="table table-striped table-bordered text-center">
            <thead className="table-dark">
              <tr>
                <th>Time</th>
                <th>Delhi</th>
                <th>Mumbai</th>
                <th>Kolkata</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.time}</td>
                    <td>{item.Delhi}</td>
                    <td>{item.Mumbai}</td>
                    <td>{item.Kolkata}</td>
                    <td>
                      <i
                        className="fas fa-edit text-primary me-3"
                        onClick={() => openEditModal(item)}
                      ></i>
                      <i
                        className="fas fa-trash text-danger"
                        onClick={() => handleDelete(item.id)}
                      ></i>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">No Data Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div
        className={`modal fade ${modalIsOpen ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: modalIsOpen ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Data</h5>
              <button
                type="button"
                className="close"
                onClick={closeModal}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>Time</label>
                  <input
                    type="text"
                    className="form-control"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    placeholder="Enter time"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Delhi</label>
                  <input
                    type="number"
                    className="form-control"
                    value={delhi}
                    onChange={(e) => setDelhi(e.target.value)}
                    placeholder="Enter Delhi data"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Mumbai</label>
                  <input
                    type="number"
                    className="form-control"
                    value={mumbai}
                    onChange={(e) => setMumbai(e.target.value)}
                    placeholder="Enter Mumbai data"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Kolkata</label>
                  <input
                    type="number"
                    className="form-control"
                    value={kolkata}
                    onChange={(e) => setKolkata(e.target.value)}
                    placeholder="Enter Kolkata data"
                    required
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                onClick={handleAddData}
              >
                Add Data
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade ${editModalIsOpen ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: editModalIsOpen ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Data</h5>
              <button
                type="button"
                className="close"
                onClick={closeEditModal}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>Time</label>
                  <input
                    type="text"
                    className="form-control"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    placeholder="Enter time"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Delhi</label>
                  <input
                    type="number"
                    className="form-control"
                    value={delhi}
                    onChange={(e) => setDelhi(e.target.value)}
                    placeholder="Enter Delhi data"
                  />
                </div>
                <div className="form-group">
                  <label>Mumbai</label>
                  <input
                    type="number"
                    className="form-control"
                    value={mumbai}
                    onChange={(e) => setMumbai(e.target.value)}
                    placeholder="Enter Mumbai data"
                  />
                </div>
                <div className="form-group">
                  <label>Kolkata</label>
                  <input
                    type="number"
                    className="form-control"
                    value={kolkata}
                    onChange={(e) => setKolkata(e.target.value)}
                    placeholder="Enter Kolkata data"
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                onClick={handleEditData}
              >
                Save changes
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeEditModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
